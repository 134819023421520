import { NavLink } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import _ from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import RatingInput from 'components/FormComponents/Rating';
import CheckboxAndRadio from 'components/FormComponents/CheckboxAndRadio';
import TextField from 'components/FormComponents/TextField';
import { RECAPTCHA_KEY } from 'config';
import CopyButton from './PostGoogleReview';

const defaultValues = {
  borrower: '',
  finance: '',
  purposeOfLoan: '',
  reviewer_type: '',
  closingDate: '',
  mortgage: '',
  zipCode: '',
  officerRating: 0,
  npsScoreLO: 0,
  comment: '',
  tos: false,
  recaptcha: null,
};

const schemaValidation = yup
  .object({
    borrower: yup.string().required('Name Required.'),
    finance: yup.string().required('Choose an option.'),
    purposeOfLoan: yup.string().required('Choose an option.'),
    reviewer_type: yup.string().required('Choose an option.'),
    closingDate: yup
      .string()
      .required('Enter closing date.')
      .test({
        test: (value, ctx) => {
          const closingDate = value.split('/');
          const month = closingDate[0];
          const year = closingDate[1];
          if (isNaN(month) || isNaN(year) || !month || !year) {
            return ctx.createError({ message: 'Please write MM/YYYY format.' });
          } else if (parseInt(month, 10) > 12 || parseInt(month, 10) < 1) {
            return ctx.createError({ message: 'Month should be between 1-12.' });
          } else if (year.length !== 4) {
            return ctx.createError({ message: 'Year Should be in YYYY format.' });
          }
          return true;
        },
      }),
    mortgage: yup.string().test({
      test: (value, ctx) => {
        if (value !== '' && isNaN(parseInt(value, 10))) {
          return ctx.createError({ message: 'Enter a valid number.' });
        } else if (parseInt(value, 10) > 999_999_999_999) {
          return ctx.createError({ message: 'Loan amount cannot be greater than 999,999,999,999' });
        }
        return true;
      },
    }),
    zipCode: yup.string().required('Zip Code Required.'),
    officerRating: yup.number().required().min(1, 'Rating is required.').max(5),
    npsScoreLO: yup.number().required().min(1, 'NPS Score is required.').max(10),
    comment: yup.string().required('Comment Required.'),
    tos: yup.boolean().isTrue(),
    recaptcha: yup.string().nullable().trim().required(),
  })
  .required();

function SurveyForm({ officer, agentProfile, comment, onSubmit }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
  } = useForm({ mode: 'onTouched', defaultValues, resolver: yupResolver(schemaValidation) });

  const tosValue = watch('tos');
  const recaptchaValue = watch('recaptcha');

  return (
    <div>
      {agentProfile.submittedSuccess === true ? (
        <div className='survey-success'>
          <h3>Thanks for your review!</h3>
          <p>
            On behalf of {officer.name} and LENDAID, thank you for sharing your mortgage experience to help home buyers
            find top rated loan officers. Your review will be posted to the LENDAID website.
          </p>
          <br />
          {officer.business_location !== null && (
            <CopyButton reviewComment={comment} businessGoogleUrl={officer.business_location.review_url} />
          )}
        </div>
      ) : (
        <div>
          <h2>Add a Review</h2>
          <p>
            On behalf of {officer.name} and LENDAID, thank you in advance for sharing your mortgage experience to help
            homebuyers find top rated loan officers. Your review will be posted to the LENDAID website.
          </p>

          <br />
          <br />

          <h3>OUR ONE MINUTE SURVEY</h3>
          <form onSubmit={handleSubmit(onSubmit)} className='form'>
            <div className='form-row'>
              <TextField
                id='borrower'
                type='text'
                placeholder='John D'
                label='Please enter your first name and last initial:'
                {...register('borrower', { required: { value: true, message: 'Name required.' } })}
                errorMessage={errors?.borrower?.message}
                containerClass='form-item'
                inputClass='col-3'
              />
            </div>

            <div className='form-row'>
              <div className='form-item'>
                <p className='label'>Did you work with {officer.name}?</p>
                <div className='radio-group'>
                  <CheckboxAndRadio
                    id='finance-yes'
                    type='radio'
                    label='Yes'
                    value='yes'
                    {...register('finance')}
                    containerClass='col-3'
                  />

                  <CheckboxAndRadio
                    id='finance-no'
                    type='radio'
                    label='No'
                    value='no'
                    {...register('finance')}
                    containerClass='col-3'
                  />
                </div>
                <span className='error-message'>{errors?.finance?.message}</span>
              </div>
            </div>

            <div className='form-row'>
              <div className='form-item'>
                <p className='label'>What was the purpose of the loan?</p>
                <div className='radio-group'>
                  <CheckboxAndRadio
                    id='purposeOfLoan-bu'
                    type='radio'
                    label='Purchase'
                    value='BU'
                    {...register('purposeOfLoan')}
                    containerClass='col-3'
                  />

                  <CheckboxAndRadio
                    id='purposeOfLoan-re'
                    type='radio'
                    label='Refinance'
                    value='RE'
                    {...register('purposeOfLoan')}
                    containerClass='col-3'
                  />
                </div>
                <span className='error-message'>{errors?.purposeOfLoan?.message}</span>
              </div>
            </div>

            <div className='form-row'>
              <div className='form-item'>
                <p className='label'>You are a: </p>
                <div className='radio-group'>
                  <CheckboxAndRadio
                    id='reviewer_type-ho'
                    type='radio'
                    label='Homeowner'
                    value='HO'
                    {...register('reviewer_type')}
                    containerClass='col-3'
                  />

                  <CheckboxAndRadio
                    id='reviewer_type-rp'
                    type='radio'
                    label='Referral Partner'
                    value='RP'
                    {...register('reviewer_type')}
                    containerClass='col-3'
                  />
                </div>
                <span className='error-message'>{errors?.reviewer_type?.message}</span>
              </div>
            </div>

            <div className='form-row'>
              <TextField
                id='closingDate'
                type='text'
                placeholder='MM/YYYY'
                label='Please enter your closing date below (month/year):'
                {...register('closingDate')}
                errorMessage={errors?.closingDate?.message}
                containerClass='form-item'
                inputClass='col-3'
              />
            </div>

            <div className='form-row'>
              <TextField
                id='mortgage'
                type='number'
                placeholder='Loan Amount'
                label='Please enter the loan amount below (in dollars):'
                {...register('mortgage')}
                errorMessage={errors?.mortgage?.message}
                containerClass='form-item'
                inputClass='col-3'
              />
            </div>

            <div className='form-row'>
              <TextField
                id='zipCode'
                type='text'
                placeholder='Zip Code'
                label='Please enter the property address below:'
                {...register('zipCode', { required: true })}
                errorMessage={errors?.zipCode?.message}
                containerClass='form-item'
                inputClass='col-3'
              />
            </div>

            <div className='form-row'>
              <RatingInput
                id='officerRating'
                label={`How would you rate your experience with ${officer.name}?`}
                helpText='(1=unhappy, 5=very happy)'
                {...register('officerRating')}
                setValue={setValue}
                clearErrors={clearErrors}
                containerClass='form-item'
                errorMessage={errors?.officerRating?.message}
              />
            </div>
            <div className='form-row'>
              <RatingInput
                id='npsScoreLO'
                label={`On a scale of 1-10, how likely are you to recommend ${officer.name} to others?`}
                nStars={10}
                helpText='(1=very unlikely, 10=very likely)'
                {...register('npsScoreLO')}
                setValue={setValue}
                clearErrors={clearErrors}
                containerClass='form-item'
                errorMessage={errors?.npsScoreLO?.message}
              />
            </div>
            <div className='form-row'>
              <TextField
                id='comment'
                type='textarea'
                placeholder='Comment'
                label='Additional comments?'
                helpText='Max 1000 characters'
                {...register('comment')}
                maxLength='1000'
                errorMessage={errors?.comment?.message}
                containerClass='form-item'
              />
            </div>

            <div className='form-row'>
              <CheckboxAndRadio
                id='tos'
                type='checkbox'
                label={
                  <>
                    I certify I am a real customer or referral partner with {officer.name} and this is my personal
                    experience. I understand LENDAID&apos;s{' '}
                    <NavLink end to='/tos'>
                      Terms of Use
                    </NavLink>
                  </>
                }
                {...register('tos')}
                containerClass='form-item'
              />
            </div>

            <div className='form-row'>
              <ReCAPTCHA
                sitekey={RECAPTCHA_KEY}
                onChange={value => {
                  setValue('recaptcha', value);
                }}
                className='recaptcha'
              />
            </div>

            <div className='form-row'>
              <button
                type='submit'
                disabled={!_.isEmpty(errors) || !recaptchaValue || !tosValue || agentProfile.submittedSuccess}
                className='button arrow small'
              >
                Submit
              </button>
            </div>

            <div className='form-row'>
              <span className='help-text'>
                <strong>NOTE:</strong> All fields are required.{' '}
              </span>
            </div>

            <div className='search-legal-term'>
              It is our mission to build trustworthy loan officer profiles to best guide mortgage borrowers. Thank you
              for taking the time to share your experience so that future borrowers can have the best experience
              possible.
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default SurveyForm;
